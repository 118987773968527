const acolumns = [
  {
    title: 'No',
    slots: { customRender: 'no' },
  },
  {
    title: 'Nama Program',
    dataIndex: 'nama_program',
  },
  {
    title: 'Region Program',
    dataIndex: 'region_program',
  },
  {
    title: 'Tanggal Registrasi',
    dataIndex: 'tanggal_registrasi',
    slots: { customRender: 'formatDate' },
  },
  {
    title: 'Kode Toko',
    dataIndex: 'kode_toko',
  },
  {
    title: 'Nama Toko',
    dataIndex: 'nama_toko',
  },
  {
    title: 'Area Toko',
    dataIndex: 'area_toko',
  },
  {
    title: 'Region Toko',
    dataIndex: 'region_toko',
  },
  {
    title: 'Paket Program',
    dataIndex: 'paket_program',
  },
  {
    title: 'Tipe',
    dataIndex: 'tipe',
  },
  {
    title: 'Nama Pemilik',
    dataIndex: 'nama_pemilik',
  },
  {
    title: 'Nama Sales',
    dataIndex: 'nama_sales',
  },
  {
    title: 'No Telepon',
    dataIndex: 'no_telepon',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Lampiran',
    dataIndex: 'lampiran',
    slots: { customRender: 'lampiran' },
  },
  {
    title: '1st Main Brand',
    dataIndex: '1st_main_brand',
  },
  {
    title: '2nd Main Brand',
    dataIndex: '2nd_main_brand',
  },
  {
    title: 'Eco Brand',
    dataIndex: 'eco_brand',
  },
]
export default acolumns
