<template>
  <div>
    <a-alert
      v-if="errorMessage"
      type="error"
      :message="errorMessage"
      banner
      closable
      @close="errorMessage = null"
    />
    <div class="row">
      <div class="col-md-12">
        <filter-regional placeholder="Pilih Regional Toko" class="mr-2" v-model:value="region" />
        <filter-area
          class="mt-2 mr-2"
          placeholder="Pilih Area Toko"
          v-model:value="area"
          v-model:area="area"
          v-model:region="region"
        />
        <filter-toko v-model:value="toko" class="mt-2 mr-2" />
        <filter-program v-model:value="program" class="mt-2" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
         <a-input-search
          v-model:value="q"
          placeholder="Cari ..."
          style="width: 300px"
          class="mr-2 mt-2"
        />
        <a-button class="mt-2 mr-2" type="primary" title="cari" @click="search" :loading="isFetching">
          <i class="fa fa-search" aria-hidden="true" v-if="!isFetching"></i>
          <span v-else>Memuat Data ...</span>
        </a-button>
      </div>
    </div>
    <div class="row justify-content-end mb-2">
      <div class="col-lg-6 col-md-12"></div>
      <div class="col-lg-6 col-md-12 text-right">
        <a-button title="Download Excel" type="primary" :loading="isDownloading" @click="fetchXlsx">
          <i class="fa fa-file-excel-o" aria-hidden="true" v-if="!isDownloading"></i>
          <span v-else>Downloading ...</span>
        </a-button>
      </div>
    </div>
    <div class="table-responsive text-nowrap mt-2">
      <md-table
        row-key="id"
        :columns="columns"
        :data-source="data"
        size="small"
        :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
        :pagination="{
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
          showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
          total: meta.totalCount,
          pageSize: meta.perPage,
          current: meta.currentPage,
        }"
        @change="handleTableChange"
        :loading="isFetching"
      >
        <template #no="{ index }">
          <span>
            {{ startRow + index }}
          </span>
        </template>
        <template #formatDate="{text}">
          <span>{{ text ? moment(text).format('DD-MM-yyyy') : '' }}</span>
        </template>
        <template #lampiran="{ record }">
            <span>
                <ATooltip title="Detail KTP">
                    <AButton
                        class="button"
                        size="small"
                        @click="detailByImage(record)">
                        Detail KTP
                    </AButton>
                </ATooltip>
            </span>
        </template>
      </md-table>
    </div>

      <!-- detail by image -->
      <DetailByImage
          v-if="visibleImageModal"
          v-model:visible="visibleImageModal"
          v-model:item="visibleImageItemModal"/>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, computed, watch } from 'vue'
import acolumns from './columns'
import FilterRegional from '@/components/filter/FilterRegional'
import FilterArea from '@/components/filter/FilterArea'
import FilterToko from '@/components/filter/FilterToko'
import FilterProgram from '@/components/filter/FilterProgram'
import DetailByImage from './DetailByImage'
import qs from 'qs'
import moment from 'moment'
import apiClient from '@/services/axios'

export default defineComponent({
  components: {
    FilterRegional,
    FilterArea,
    FilterToko,
    FilterProgram,
    DetailByImage,
  },
  emits: ['handleOk'],
  setup() {
    const state = reactive({
      data: [],
      perPage: 10,
      pageCount: 0,
      totalCount: 0,
      meta: {
        totalCount: 0,
        perPage: 10,
        currentPage: 1,
      },
      page: 1,
      isFetching: false,
      isDownloading: false,
      search: null,
      region: [],
      area: [],
      toko: [],
      program: [],
      q: null,
      params: [],
    })

    const visible = ref(false)

    const handleChange = (pag, filters, sorter) => {
      meta.value.currentPage = pag.current
      meta.value.perPage = pag.pageSize
    }

    const perPage = ref(10)
    const pageCount = ref(0)
    const totalCount = ref(0)
    const meta = ref({})
    const page = ref(1)
    const startRow = ref(1)

    const handleTableChange = (pag, filters, sorter) => {
      page.value = pag.current
      perPage.value = pag.pageSize
      fetchData()
      startRow.value = (page.value - 1) * perPage.value + 1
    }

    const model = reactive({ readOnly: false })

    const handleOk = form => {
      visible.value = false
      fetchData()
    }

    const params = ref({})
    const errorMessage = ref()

    const columns = ref([])
    columns.value = acolumns

    const fetchData = () => {
      const _params = {
        page: page.value,
        'per-page': perPage.value,
        region: state.region,
        area: state.area,
        kode_toko: state.toko,
        program: state.program,
        q: state.q,
      }
      params.value = Object.assign({}, _params)
      state.isFetching = true
      apiClient
        .get('/api/laporan-aksestoko/registrasi-program', {
          params: _params,
        })
        .then(response => {
          const { items, _meta } = response.data
          state.data = items
          state.meta = _meta || { pageCount: 0, totalCount: 0 }
          state.pageCount = state.meta.pageCount
          state.totalCount = state.meta.totalCount
        })
        .finally(() => {
          state.isFetching = false
        })
    }

    const fetchXlsx = () => {
      state.isDownloading = true
      apiClient
        .get('/api/laporan-aksestoko/registrasi-program', {
          params: {
            ...params.value,
            _export: 'xls',
            _columns: columns.value
              .filter(c => c.dataIndex !== null && c.dataIndex !== undefined && c.export !== false)
              .map(({ dataIndex: key, title, title_xls }) => ({
                key,
                title: title_xls ? title_xls : title,
              })),
          },
          paramsSerializer: function(params) {
            return qs.stringify({ ...params }, { arrayFormat: 'indices' })
          },
          responseType: 'blob',
          headers: {},
        })
        .then(response => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `laporan-registrasi-program_${moment().format('DDMMYY_HHmm')}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(async error => {
          errorMessage.value = null
          if (error.response) {
            const { status, statusText } = error.response
            const message = JSON.parse(await error.response.data.text()).message
            errorMessage.value = `Kode error ${status}, ${statusText} : ${message}`
          }
        })
        .finally(() => {
          state.isDownloading = false
        })
    }

    // handle detail by rule name
    const visibleImageModal = ref(false);
    const visibleImageItemModal = ref(null);

    const detailByImage = (item) => {
      visibleImageModal.value = true
      visibleImageItemModal.value = item
    }

    onMounted(() => {
      fetchData()
    })

    const search = () => {
      fetchData()
    }

    return {
      perPage,
      pageCount,
      totalCount,
      meta,
      page,
      startRow,
      visible,
      handleOk,
      fetchXlsx,
      columns,
      handleChange,
      handleTableChange,
      model,
      ...toRefs(state),
      search,
      errorMessage,
      moment,
      visibleImageModal,
      detailByImage,
      visibleImageItemModal,
    }
  },
})
</script>
<style scoped>
.card-body {
  margin-left: 20px;
}
.button {
  margin: 0 3px;
}
</style>
