<template>
    <AModal
        :visible="visible"
        title="Detail KTP"
        @cancel="handleModalCancel"
        :footer="null">
        <AImage
            v-if="item.lampiran"
            :src="item.lampiran"/>
        <p v-else>KTP tidak ditemukan.</p>
    </AModal>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        visible: [Boolean],
        item: {
            type: [Object],
            default: () => {},
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        return {
            handleModalCancel,
        }
    },
})
</script>