<template>
  <a-select
    :mode="mode"
    v-model:value="valueState"
    style="width: 300px"
    placeholder="Pilih Program"
    allow-clear
    :show-arrow="true"
    not-found-content="Tidak ditemukan"
    option-filter-prop="label"
    option-label-prop="label"
    @search="onSearch"
    @blur="() => (findText = null)"
    @select="() => (findText = null)"
    @deselect="deselect"
    @dropdownVisibleChange="dropdownVisibleChange"
    show-search
    v-bind="$attrs"
  >
    <a-select-option v-for="d in items" :key="d.id" :label="(d.code)">
      <!-- eslint-disable vue/no-v-html -->
      <span v-html="highlight(d.code)"></span>
      <!--eslint-enable-->
    </a-select-option>
  </a-select>
</template>

<script>
import { onMounted, ref, toRefs, defineComponent } from 'vue'
import apiClient from '@/services/axios'
import { useVModel } from '@/components/useVModel.js'

export default {
  props: {
    value: { type: [Array, Number, Object, String], default: null },
    mode: {
      default: 'multiple',
      type: String,
    },
  },
  emits: ['update:value', 'select'],
  setup(props, { emit }) {
    const items = ref([])
    const findText = ref(null)

    const fetch = () => {
      apiClient.get('/api/filter/loyalty-program').then(response => {
        const sorted = response.data
        items.value = sorted.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
      })
    }
    onMounted(() => {
      fetch()
    })

    const onSearch = value => {
      findText.value = value
    }

    const select = value => {
      if (findText.value !== null) {
        fetchData()
        findText.value = null
      }
      emit('select', value)
    }

    const deselect = value => {
      console.log({ deselect: '', value })
    }

    const dropdownVisibleChange = value => {
      // if (findText.value !== null) {
      //   fetchData()
      //   findText.value = null
      // }
      findText.value = value
    }

    const highlight = value => {
      return value.replace(new RegExp(findText.value, 'gi'), match => {
        return `<span style="background-color: yellow;">${match}</span>`
      })
    }
    return {
      items,
      valueState: useVModel(props, 'value'),
      findText,
      highlight,
      onSearch,
      deselect,
      dropdownVisibleChange,
      select,
    }
  },
}
</script>

<style></style>
